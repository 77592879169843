<template>
  <v-data-table
    :headers="headers"
    :items="releases"
    :options.sync="options"
    :server-items-length="total"
    :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
          itemsPerPageOptions: [10, 25, 50, 100]
        }"
    :loading="loading"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
        color="white"
      >
        <v-toolbar-title>{{ $t("lbl.releases") }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="auth('manageReleases')"
              color="primary"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              :disabled="disable"
            >{{
              $t("lbl.newRelease")
            }}</v-btn>
          </template>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.version"
                        :rules="rules.version"
                        :label="$t('lbl.version')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.notes"
                        :label="$t('lbl.notes')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        v-model="editedItem.whitelist"
                        :items="editedItem.whitelist"
                        :rules="rules.whitelist"
                        :label="$t('lbl.whitelist')"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="reset"
                >{{ $t("lbl.clear") }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >{{
                  $t("lbl.cancel")
                }}</v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="!valid"
                  @click="save"
                >{{
                  $t("lbl.save")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.date`]="{ item }">
      <span>
        {{ item.date && $luxon.fromISO(item.date).toLocaleString(fmt) }}
      </span>
    </template>
    <template v-slot:[`item.whitelist`]="{ item }">
      <span>
        <v-chip
          v-for="uid in item.whitelist"
          :key="uid"
        >
          {{ uid.split("-")[0] }}
        </v-chip>
      </span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        class="mr-2"
        :disabled="disable"
        @click="editItem(item)"
      > mdi-pencil </v-icon>
      <v-icon
        :disabled="disable"
        @click="deleteItem(item)"
      > mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";
import { DateTime } from "luxon";
import { required, semver, whitelist } from "@/common/validation";

export default {
  data: () => ({
    dialog: false,
    disable: false,
    valid: true,
    loading: true,
    options: {},
    total: 0,
    releases: [],
    fmt: DateTime.DATETIME_SHORT,
    editedIndex: -1,
    editedItem: {
      version: "",
      notes: "",
      whitelist: [],
    },
    defaultItem: {
      version: "",
      notes: "",
      whitelist: [],
    },
    rules: {
      version: [required, semver],
      whitelist: [required, whitelist],
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("lbl.newRelease")
        : this.$t("lbl.editRelease");
    },
    headers() {
      const columns = [
        { text: this.$t("lbl.version"), value: "version" },
        { text: this.$t("lbl.date"), value: "date" },
        { text: this.$t("lbl.whitelist"), value: "whitelist" },
        { text: this.$t("lbl.notes"), value: "notes" },
      ];
      if (this.auth("manageReleases")) {
        columns.push({
          text: this.$t("lbl.actions"),
          value: "actions",
          align: "center",
          sortable: false,
        });
      }
      return columns;
    },
  },

  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    // eslint-disable-next-line func-names
    "editedItem.whitelist": function (val) {
      if (val) {
        const list = this.editedItem.whitelist.map((x) => x.split(" ")).flat();
        const ulist = [...new Set(list)];
        const sulist = ulist.sort(
          (a, b) => (a === "*" && -1) || (b === "*" && 1) || 0,
        );
        this.editedItem.whitelist.length = 0;
        for (let i = 0; i < sulist.length; i += 1) {
          this.editedItem.whitelist[i] = sulist[i];
        }
      }
    },
  },

  methods: {
    ...mapActions("release", [
      "findReleases",
      "getRelease",
      "saveRelease",
      "deleteRelease",
    ]),

    initialize() {
      this.loading = true;
      const filters = this.getFilters(this.options);
      this.findReleases(filters)
        .then(({ results, totalResults }) => {
          this.releases = results;
          this.total = totalResults;
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reset() {
      this.$refs.form.reset();
    },

    close() {
      this.reset();
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    editItem(item) {
      this.editedIndex = this.releases.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      this.$swal({
        html: this.$t("msg.deleteText", { item: item.name }),
        icon: "warning",
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("lbl.delete"),
        cancelButtonText: this.$t("lbl.cancel"),
      }).then((result) => {
        if (result.value) {
          this.disable = true;
          this.deleteRelease(item.id)
            .then(() => {
              this.initialize();
              this.$toasted.success(
                this.$t("msg.delete", { item: this.$t("lbl.release") }),
              );
            })
            .catch((error) => {
              this.$toasted.error(this.getMessageText(error.message));
            })
            .finally(() => {
              this.disable = false;
            });
        }
      });
    },

    save() {
      this.dialog = false;
      this.disable = true;
      const editedItem = { ...this.editedItem };
      return this.saveRelease(editedItem)
        .then(() => {
          this.initialize();
          this.$toasted.success(
            this.$t("msg.save", { item: this.$t("lbl.release") }),
          );
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        })
        .finally(() => {
          this.disable = false;
        });
    },
  },
};
</script>
