<template>
  <v-list>
    <div
      v-for="(item, i) in items"
      :key="i"
    >
      <template v-if="!item.hide && item.group && auth(item.right)">
        <v-list-group color="">
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="({ icon, title, to, right, hide }, i) in item.group"
            :key="i"
            :to="to"
            v-show="!hide && auth(right)"
            router
            exact
            class="px-8"
          >
            <v-list-item-action>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
      <template v-else>
        <v-list-item
          :to="item.to"
          v-if="!item.hide && auth(item.right)"
          router
          exact
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </div>
  </v-list>
</template>

<script>
export default {
  computed: {
    items() {
      return [
        {
          icon: "mdi-apps",
          title: this.$t("nav.home"),
          to: "/",
        },
        {
          icon: "mdi-account",
          title: this.$t("nav.users"),
          to: "/user",
          right: "getUsers",
        },
        {
          icon: "mdi-devices",
          title: this.$t("nav.units"),
          to: "/unit",
          right: ["getUnits", "fetchUnits", "fetchResellerUnits"],
        },
        {
          icon: "mdi-store",
          title: this.$t("nav.resellers"),
          to: "/reseller",
          right: "getResellers",
        },
        {
          icon: "mdi-application-cog",
          title: this.$t("nav.releases"),
          to: "/release",
          right: "getReleases",
        },
        {
          icon: "mdi-database-sync",
          title: this.$t("nav.webdata"),
          group: [
            {
              icon: "mdi-lock-check",
              title: this.$t("nav.sslbumps"),
              to: "/webdata/sslbump",
              right: "getWebdatas",
            },
            {
              icon: "mdi-web",
              title: this.$t("nav.websites"),
              to: "/webdata/website",
              right: "getWebdatas",
            },
            {
              icon: "mdi-page-next",
              title: this.$t("nav.webpages"),
              to: "/webdata/webpage",
              right: "getWebdatas",
            },
          ],
          right: "getWebdatas",
        },
        {
          icon: "mdi-chart-bubble",
          title: this.$t("nav.about"),
          to: "/about",
          hide: true,
        },
      ];
    },
  },
};
</script>
