<template>
  <div>
    <Unit />
  </div>
</template>
<script>
import Unit from "@/components/Unit.vue";

export default {
  components: {
    Unit,
  },
};
</script>
