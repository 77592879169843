/* eslint-disable import/no-named-as-default-member */
import i18n from "../i18n";
import rx from "./regex";

export const required = (v) => !!v || i18n.t("val.required");
export const minLength = (number) => (v) => (v || "").length >= number || i18n.t("val.minLength", { number });
export const maxLength = (number) => (v) => (v || "").length <= number || i18n.t("val.maxLength", { number });
export const email = (v) => rx.email.test(v) || i18n.t("val.email");
export const password = (v) => rx.password.test(v) || i18n.t("val.password");
export const semver = (v) => rx.semver.test(v) || i18n.t("val.semver");
export const guid = (v) => rx.guid.test(v) || i18n.t("val.guid");
export const whitelist = (v) => {
  if (!Array.isArray(v)) {
    return i18n.t("val.required");
  }
  return v.every((item) => rx.guid.test(item) || item === "*") || i18n.t("val.whitelist");
};
