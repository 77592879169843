import { ResellerService } from "@/common/api.service";

const initialState = {};

const state = { ...initialState };

const getters = {};

const mutations = {};

const actions = {
  findResellers(_, params) {
    return ResellerService.find(params).then(({ data }) => data);
  },
  getReseller(_, id) {
    return ResellerService.get(id).then(({ data }) => data);
  },
  saveReseller(_, { id, ...body }) {
    const request = id
      ? ResellerService.update(id, body)
      : ResellerService.create(body);

    return request.then(({ data }) => data);
  },
  deleteReseller(_, id) {
    return ResellerService.delete(id).then(({ data }) => data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
