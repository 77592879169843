<template>
  <v-layout
    align-center
    justify-center
  >
    <v-flex
      xs12
      sm8
      md6
      lg4
    >
      <v-card class="elevation-12 mt-16">
        <v-toolbar
          dark
          color="primary"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t("lbl.signin") }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              prepend-icon="mdi-account"
              v-model="details.email"
              :label="$t('lbl.email')"
              type="email"
              required
              :rules="rules.emailRules"
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-lock"
              v-model="details.password"
              :label="$t('lbl.password')"
              type="password"
              required
              :rules="rules.passwordRules"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click.native="loginUser"
          >{{ $t("lbl.login") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";
import { required, email } from "@/common/validation";

export default {
  data() {
    return {
      details: {
        email: "",
        password: "",
      },
      rules: {
        emailRules: [required, email],
        passwordRules: [required],
      },
    };
  },
  methods: {
    ...mapActions("auth", ["login"]),

    loginUser() {
      const isValid = this.$refs.form.validate(true);
      if (!isValid) {
        return;
      }
      this.login(this.details)
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        });
    },
  },
};
</script>

<style scoped>
.v-toolbar__title {
  font-size: 1.5rem !important;
}
</style>
