<template>
  <v-data-table
    :headers="headers"
    :items="resellers"
    :options.sync="options"
    :server-items-length="total"
    :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
          itemsPerPageOptions: [10, 25, 50, 100]
        }"
    :loading="loading"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
        color="white"
      >
        <v-toolbar-title>{{ $t("lbl.resellers") }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="auth('manageResellers')"
              color="primary"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              :disabled="disable"
            >{{
              $t("lbl.newReseller")
            }}</v-btn>
          </template>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.name"
                        :rules="rules.nameRules"
                        :label="$t('lbl.name')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="editedItem.fullname"
                        :rules="rules.nameRules"
                        :label="$t('lbl.fullname')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="reset"
                >{{ $t("lbl.clear") }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >{{
                  $t("lbl.cancel")
                }}</v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="!valid"
                  @click="save"
                >{{
                  $t("lbl.save")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        class="mr-2"
        :disabled="disable"
        @click="editItem(item)"
      > mdi-pencil </v-icon>
      <v-icon
        :disabled="disable"
        @click="deleteItem(item)"
      > mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "@/common/validation";

export default {
  data: () => ({
    dialog: false,
    disable: false,
    valid: true,
    loading: true,
    options: {},
    total: 0,
    resellers: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      fullname: "",
    },
    defaultItem: {
      name: "",
      fullname: "",
    },
    rules: {
      nameRules: [required],
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("lbl.newReseller")
        : this.$t("lbl.editReseller");
    },
    headers() {
      const columns = [
        { text: this.$t("lbl.name"), value: "name" },
        { text: this.$t("lbl.fullname"), value: "fullname" },
      ];
      if (this.auth("manageResellers")) {
        columns.push({
          text: this.$t("lbl.actions"),
          value: "actions",
          align: "center",
          sortable: false,
        });
      }
      return columns;
    },
  },

  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions("reseller", [
      "findResellers",
      "getReseller",
      "saveReseller",
      "deleteReseller",
    ]),

    initialize() {
      this.loading = true;
      const filters = this.getFilters(this.options);
      this.findResellers(filters)
        .then(({ results, totalResults }) => {
          this.resellers = results;
          this.total = totalResults;
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reset() {
      this.$refs.form.reset();
    },

    close() {
      this.reset();
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    editItem(item) {
      this.editedIndex = this.resellers.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      this.$swal({
        html: this.$t("msg.deleteText", { item: item.name }),
        icon: "warning",
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("lbl.delete"),
        cancelButtonText: this.$t("lbl.cancel"),
      }).then((result) => {
        if (result.value) {
          this.disable = true;
          this.deleteReseller(item.id)
            .then(() => {
              this.initialize();
              this.$toasted.success(
                this.$t("msg.delete", { item: this.$t("lbl.reseller") }),
              );
            })
            .catch((error) => {
              this.$toasted.error(this.getMessageText(error.message));
            })
            .finally(() => {
              this.disable = false;
            });
        }
      });
    },

    save() {
      this.dialog = false;
      this.disable = true;
      const editedItem = { ...this.editedItem };
      return this.saveReseller(editedItem)
        .then(() => {
          this.initialize();
          this.$toasted.success(
            this.$t("msg.save", { item: this.$t("lbl.reseller") }),
          );
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        })
        .finally(() => {
          this.disable = false;
        });
    },
  },
};
</script>
