import { ReleaseService } from "@/common/api.service";

const initialState = {};

const state = { ...initialState };

const getters = {};

const mutations = {};

const actions = {
  findReleases(_, params) {
    return ReleaseService.find(params).then(({ data }) => data);
  },
  getRelease(_, id) {
    return ReleaseService.get(id).then(({ data }) => data);
  },
  saveRelease(_, { id, date, ...body }) {
    const request = id
      ? ReleaseService.update(id, body)
      : ReleaseService.create(body);

    return request.then(({ data }) => data);
  },
  deleteRelease(_, id) {
    return ReleaseService.delete(id).then(({ data }) => data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
