var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.releases,"options":_vm.options,"server-items-length":_vm.total,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
        itemsPerPageOptions: [10, 25, 50, 100]
      },"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("lbl.releases")))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(_vm.auth('manageReleases'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","disabled":_vm.disable}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t("lbl.newRelease")))]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"rules":_vm.rules.version,"label":_vm.$t('lbl.version')},model:{value:(_vm.editedItem.version),callback:function ($$v) {_vm.$set(_vm.editedItem, "version", $$v)},expression:"editedItem.version"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.notes')},model:{value:(_vm.editedItem.notes),callback:function ($$v) {_vm.$set(_vm.editedItem, "notes", $$v)},expression:"editedItem.notes"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"clearable":"","multiple":"","small-chips":"","deletable-chips":"","outlined":"","items":_vm.editedItem.whitelist,"rules":_vm.rules.whitelist,"label":_vm.$t('lbl.whitelist')},model:{value:(_vm.editedItem.whitelist),callback:function ($$v) {_vm.$set(_vm.editedItem, "whitelist", $$v)},expression:"editedItem.whitelist"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.reset}},[_vm._v(_vm._s(_vm.$t("lbl.clear")))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.$t("lbl.cancel")))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.valid},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("lbl.save")))])],1)],1)],1)],1)],1)]},proxy:true},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.date && _vm.$luxon.fromISO(item.date).toLocaleString(_vm.fmt))+" ")])]}},{key:"item.whitelist",fn:function(ref){
      var item = ref.item;
return [_c('span',_vm._l((item.whitelist),function(uid){return _c('v-chip',{key:uid},[_vm._v(" "+_vm._s(uid.split("-")[0])+" ")])}),1)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"disabled":_vm.disable},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"disabled":_vm.disable},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }