import axios from "axios";
import router from "../router";
import JwtService from "./jwt.service";
import { apiUrl } from "./config";

const service = axios.create({
  baseURL: apiUrl,
});

// Token Refresh
let subscribers = [];

function onAccessTokenFetched(token) {
  subscribers = subscribers.filter((callback) => callback(token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

function fetchRefreshToken(refreshToken) {
  JwtService.deleteTokens();
  return service.post("/auth/refresh-tokens", { refreshToken });
}

service.interceptors.request.use(
  (config) => {
    const accessToken = JwtService.getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => response,
  (error) => {
    // In case of network error, api server shutdown, timeouts...
    if (!error.response) {
      return Promise.reject(error);
    }
    const { config, response } = error;
    const originalRequest = config;

    if (response.status === 401) {
      const refreshToken = JwtService.getRefreshToken();
      if (refreshToken) {
        fetchRefreshToken(refreshToken)
          .then(({ data }) => {
            JwtService.updateTokens(data);
            onAccessTokenFetched(data.access.token);
          })
          .catch(() => {
            // 401 returned again, refreshToken expired
            subscribers = [];
            JwtService.deleteSession();
          });

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(service(originalRequest));
          });
        });
        return retryOriginalRequest;
      }
      router.push("/login", () => {});
    }
    // Return only response data from api server 400, 401, 403, 404, 500 responses
    return Promise.reject(response.data);
  },
);

export default service;
