import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";
import user from "./user.module";
import unit from "./unit.module";
import reseller from "./reseller.module";
import release from "./release.module";
import webdata from "./webdata.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pageTitle: "",
  },
  getters: {
  },
  mutations: {
    setTitle(state, data) {
      state.pageTitle = data;
    },
  },
  actions: {
  },
  modules: {
    auth,
    user,
    unit,
    reseller,
    release,
    webdata,
  },
});
