<template>
  <div>
    <Reseller />
  </div>
</template>
<script>
import Reseller from "@/components/Reseller.vue";

export default {
  components: {
    Reseller,
  },
};
</script>
