<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="units"
      :items-per-page="100"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :loading="loading"
      multi-sort
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-toolbar-title>{{ $t("lbl.units") }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="auth('createUnits')"
                color="primary"
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                :disabled="disable"
              >{{ $t("lbl.newUnit") }}</v-btn>
            </template>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          :readonly="editedIndex !== -1"
                          v-model="editedItem.uid"
                          :rules="rules.uidRules"
                          :label="$t('lbl.uid')"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          :readonly="editedIndex !== -1"
                          v-model="editedItem.sn"
                          :rules="rules.snRules"
                          :label="$t('lbl.sn')"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-select
                          v-model="editedItem.edition"
                          :items="editions"
                          :rules="rules.editionRules"
                          :label="$t('lbl.edition')"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          :readonly="editedIndex !== -1"
                          v-model="editedItem.version"
                          :rules="rules.versionRules"
                          :label="$t('lbl.version')"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-select
                          v-model="editedItem.reseller"
                          :items="resellers"
                          item-text="name"
                          item-value="id"
                          :rules="rules.resellerRules"
                          :label="$t('lbl.reseller')"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.notes"
                          :label="$t('lbl.notes')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="reset"
                  >{{ $t("lbl.clear") }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialog = false"
                  >{{ $t("lbl.cancel") }}</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!valid"
                    @click="save"
                  >{{ $t("lbl.save") }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="2"
            >
              <v-text-field
                outlined
                dense
                hide-details
                v-model="filter.unit"
                :label="$t('lbl.unitSearch')"
                @keydown.enter="initialize(true)"
              ></v-text-field>
            </v-col>
            <template v-if="auth(['get', 'fetchResellerUsers'])">
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  outlined
                  dense
                  hide-details
                  clearable
                  v-model="filter.user"
                  :items="users"
                  item-text="email"
                  item-value="id"
                  :label="$t('lbl.user')"
                  @change="initialize(true)"
                ></v-select>
              </v-col>
            </template>
            <template v-if="auth('get')">
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  outlined
                  dense
                  hide-details
                  v-model="filter.reseller"
                  :items="resellers"
                  item-text="name"
                  item-value="id"
                  :label="$t('lbl.reseller')"
                  @change="initialize(true)"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-datetime-picker
                  v-model="filter.start"
                  :label="$t('lbl.start')"
                  :clearText="$t('lbl.clear')"
                  :okText="$t('lbl.ok')"
                  :textFieldProps="{
                    outlined: true,
                    dense: true,
                    hideDetails: true,
                    clearable: true,
                  }"
                  :datePickerProps="{ locale: $i18n.locale, 'no-title': true }"
                  :timePickerProps="{ format: '24hr', 'no-title': true }"
                  @input="initialize(true)"
                ></v-datetime-picker>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-datetime-picker
                  v-model="filter.end"
                  :label="$t('lbl.end')"
                  :clearText="$t('lbl.clear')"
                  :okText="$t('lbl.ok')"
                  :textFieldProps="{
                    outlined: true,
                    dense: true,
                    hideDetails: true,
                    clearable: true,
                  }"
                  :datePickerProps="{ locale: $i18n.locale, 'no-title': true }"
                  :timePickerProps="{ format: '24hr', 'no-title': true }"
                  @input="initialize(true)"
                ></v-datetime-picker>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="1"
              >
                <v-select
                  outlined
                  dense
                  hide-details
                  hidden
                  v-model="filter.unboxed"
                  :items="unbox"
                  :label="$t('lbl.unbox')"
                  @change="initialize(true)"
                ></v-select>
              </v-col>
            </template>
            <v-spacer></v-spacer>
            <v-col
              cols="12"
              sm="6"
              md="1"
            >
              <v-btn
                block
                color="warning"
                @click="refresh"
              >
                <v-icon>mdi-refresh-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:[`item.edition`]="{ item }">
        <span>{{ getEnumText(editions, item.edition) }}</span>
      </template>
      <template v-slot:[`item.reseller`]="{ item }">
        <span>
          {{ item.reseller.name }}
        </span>
      </template>
      <template v-slot:[`item.unboxdate`]="{ item }">
        <span>
          {{ item.unboxdate && $luxon.fromISO(item.unboxdate).toLocaleString(fmt) }}
        </span>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <v-chip
          label
          outlined
        >
          <v-edit-dialog
            large
            :save-text="$t('lbl.save')"
            :cancel-text="$t('lbl.cancel')"
            @open="description = item.description"
            @save="saveDescription(item.id)"
          >
            {{ item.description }}
            <v-icon
              v-if="!item.description"
              small
            >
              mdi-note-text-outline
            </v-icon>
            <template v-slot:input>
              <v-text-field
                v-model="description"
                :label="$t('lbl.description')"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          top
          :disabled="!item.ip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="auth(['supportUnits', 'operateUnits'])"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              :disabled="disable"
              :color="color(item.ip)"
              @click="openOperate(item)"
            > mdi-lan-connect</v-icon>

            <v-icon
              v-if="auth('supportUnits')"
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              :disabled="disable"
              :color="color(item.ip)"
              @click="support(item)"
            > mdi-connection</v-icon>
          </template>
          <span>{{ item.ip }}</span>
        </v-tooltip>
        <v-icon
          v-if="auth('supportUnits')"
          class="mr-2"
          :disabled="disable"
          color="warning"
          @click="unitReset(item)"
        > mdi-database-refresh</v-icon>
        <v-icon
          v-if="auth('manageUnits')"
          class="mr-2"
          :disabled="disable"
          @click="editItem(item)"
        >mdi-pencil</v-icon>
        <v-icon
          v-if="auth('manageUnits')"
          :disabled="disable"
          @click="deleteItem(item)"
        >mdi-delete</v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogOperate"
      max-width="400"
    >
      <v-flex text-center>
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-icon
              x-large
              color="warning"
            >mdi-alert</v-icon>
            <p class="text-body-1 text--primary ma-0">
              <span v-html="$t('lbl.vpnConfigHelp')"></span><br />
              <a
                @click="config"
                @keypress="config"
              >{{ $t("lbl.vpnConfig") }}</a>
            </p>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              depressed
              min-width="100"
              color="primary"
              @click="operate()"
            >{{ $t("lbl.ok") }}</v-btn>
            <v-btn
              depressed
              min-width="100"
              color="error"
              @click="dialogOperate = false"
            >{{ $t("lbl.cancel") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { DateTime } from "luxon";
import { saveAs } from "file-saver";
import { required, semver, guid } from "@/common/validation";
import { unbox, editions } from "@/common/enums";

export default {
  data: () => ({
    dialog: false,
    dialogOperate: false,
    disable: false,
    valid: true,
    loading: true,
    options: {},
    total: 0,
    units: [],
    resellers: [],
    users: [],
    fmt: DateTime.DATETIME_SHORT,
    editedIndex: -1,
    editedItem: {
      uid: "",
      sn: "",
      edition: "",
      version: "",
      reseller: "",
      notes: "",
    },
    defaultItem: {
      uid: "",
      sn: "",
      edition: "",
      version: "",
      reseller: "",
      notes: "",
    },
    operatingUnit: null,
    description: "",
    rules: {
      uidRules: [required, guid],
      snRules: [required],
      resellerRules: [required],
      versionRules: [required, semver],
      editionRules: [required],
    },
    filter: {
      unit: null,
      reseller: null,
      user: null,
      start: null,
      end: null,
      unboxed: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("lbl.newUnit")
        : this.$t("lbl.editUnit");
    },
    headers() {
      const columns = [
        { text: this.$t("lbl.uid"), value: "uid" },
        { text: this.$t("lbl.sn"), value: "sn" },
        { text: this.$t("lbl.edition"), value: "edition" },
        { text: this.$t("lbl.version"), value: "version" },
        { text: this.$t("lbl.boxver"), value: "boxver" },
      ];
      if (this.auth(["get", "fetchResellerUsers"])) {
        columns.push({ text: this.$t("lbl.user"), value: "user.email" });
      }
      if (this.auth("get")) {
        columns.push(
          { text: this.$t("lbl.reseller"), value: "reseller" },
          { text: this.$t("lbl.notes"), value: "notes" },
        );
      }
      if (this.auth("reviseUnits")) {
        columns.push({ text: this.$t("lbl.description"), value: "description" });
      }
      columns.push({ text: this.$t("lbl.date"), value: "unboxdate" });

      if (this.auth(["manageUnits", "supportUnits", "operateUnits"])) {
        columns.push({
          text: this.$t("lbl.actions"),
          value: "actions",
          align: "center",
          sortable: false,
        });
      }
      return columns;
    },
    unbox() {
      return unbox();
    },
    editions() {
      return editions();
    },
  },

  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions("unit", [
      "findUnits",
      "fetchUnits",
      "fetchResellerUnits",
      "fetchResellerUsers",
      "getUnit",
      "saveUnit",
      "deleteUnit",
      "getVpnConfig",
      "fetchVpnConfig",
      "supportUnit",
      "operateUnit",
      "unitSupport",
      "resetUnit",
      "unitDescription",
    ]),
    ...mapActions("reseller", ["findResellers"]),
    ...mapActions("user", ["findUsers"]),

    async initialize(search) {
      this.loading = true;
      if (search) {
        this.options.page = 1;
      }
      const filters = this.getFilters(this.options, this.filter);
      try {
        const initUnits = this.getInitializer();
        const { results, totalResults } = await initUnits(filters);
        this.units = results;
        this.total = totalResults;
      } catch (error) {
        this.$toasted.error(this.getMessageText(error.message));
      } finally {
        this.loading = false;
      }

      if (this.auth("get")) {
        try {
          const { results } = await this.findResellers({
            limit: 0,
          });
          this.resellers = results;
        } catch (error) {
          this.$toasted.error(this.getMessageText(error.message));
        }

        try {
          const { results } = await this.findUsers({
            limit: 0,
            select: "email",
          });
          results.unshift({ id: "null", email: "-" });
          this.users = results;
        } catch (error) {
          this.$toasted.error(this.getMessageText(error.message));
        }
      }

      if (this.auth("fetchResellerUsers")) {
        try {
          const result = await this.fetchResellerUsers();
          this.users = result;
        } catch (error) {
          this.$toasted.error(this.getMessageText(error.message));
        }
      }
    },

    getInitializer() {
      let initUnits;
      if (this.auth("getUnits")) {
        initUnits = this.findUnits;
      }
      if (this.auth("fetchUnits")) {
        initUnits = this.fetchUnits;
      }
      if (this.auth("fetchResellerUnits")) {
        initUnits = this.fetchResellerUnits;
      }
      return initUnits;
    },

    reset() {
      this.$refs.form.reset();
    },

    close() {
      this.reset();
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    color(check) {
      return check ? "success" : "error";
    },

    editItem(item) {
      this.editedIndex = this.units.indexOf(item);
      const { reseller, ...body } = { ...item };
      this.editedItem = { ...body, reseller: reseller.id };
      this.dialog = true;
    },

    deleteItem(item) {
      this.$swal({
        html: this.$t("msg.deleteText", {
          item: `${item.sn ? item.sn : item.uid} - ${item.reseller.name}`,
        }),
        icon: "warning",
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("lbl.delete"),
        cancelButtonText: this.$t("lbl.cancel"),
      }).then((result) => {
        if (result.value) {
          this.disable = true;
          this.deleteUnit(item.id)
            .then(() => {
              this.initialize();
              this.$toasted.success(
                this.$t("msg.delete", { item: this.$t("lbl.unit") }),
              );
            })
            .catch((error) => {
              this.$toasted.error(this.getMessageText(error.message));
            })
            .finally(() => {
              this.disable = false;
            });
        }
      });
    },

    save() {
      this.dialog = false;
      this.disable = true;
      const editedItem = { ...this.editedItem };
      return this.saveUnit(editedItem)
        .then(() => {
          this.initialize();
          this.$toasted.success(
            this.$t("msg.save", { item: this.$t("lbl.unit") }),
          );
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        })
        .finally(() => {
          this.disable = false;
        });
    },

    refresh() {
      Object.keys(this.filter).forEach((i) => {
        this.filter[i] = null;
      });
      this.initialize();
    },

    openOperate(item) {
      if (item.ip) {
        this.operatingUnit = item;
        this.dialogOperate = true;
      }
    },

    operate() {
      this.dialogOperate = false;
      const accessUnit = this.auth("supportUnits")
        ? this.supportUnit
        : this.operateUnit;
      const { id } = this.operatingUnit;
      accessUnit(id)
        .then(({ ip, key }) => {
          window.open(`http://${ip}/operate/${key}`);
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        });
    },

    config() {
      const vpnConfig = this.auth("supportUnits")
        ? this.getVpnConfig
        : this.fetchVpnConfig;
      vpnConfig()
        .then((result) => {
          saveAs(
            new Blob([result.content], {
              type: "text/plain;charset=utf-8",
            }),
            result.name,
          );
          this.$toasted.success(
            this.$t("msg.download", { item: this.$t("lbl.vpnConfig") }),
          );
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        });
    },

    support(item) {
      this.unitSupport(item.id)
        .then(() => {
          this.$toasted.success(this.$t("lbl.ok"));
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        });
    },

    unitReset(item) {
      this.$swal({
        html: this.$t("msg.reset"),
        icon: "warning",
        input: "checkbox",
        inputPlaceholder: "Full Reset",
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("lbl.reset"),
        cancelButtonText: this.$t("lbl.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetUnit({ id: item.id, full: !!result.value })
            .then(() => {
              this.$toasted.success(this.$t("lbl.ok"));
            })
            .catch((error) => {
              this.$toasted.error(this.getMessageText(error.message));
            });
        }
      });
    },

    saveDescription(id) {
      const { description } = this;
      this.unitDescription({ id, description })
        .then(() => {
          this.initialize();
          this.$toasted.success(
            this.$t("msg.save", { item: this.$t("lbl.description") }),
          );
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        });
    },
  },
};
</script>

<style>
.swal2-html-container {
  font-size: 26px !important;
}
.swal2-checkbox,
.swal2-radio {
  font-size: 22px;
  color: red !important;
}
</style>
