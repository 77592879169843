import Vue from "vue";
import VueRouter from "vue-router";
import Default from "../layouts/Default.vue";
import Error from "../layouts/Error.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import User from "../views/User.vue";
import Unit from "../views/Unit.vue";
import Reseller from "../views/Reseller.vue";
import Release from "../views/Release.vue";
import Webdata from "../views/Webdata.vue";
import Sslbump from "../components/Sslbump.vue";
import Website from "../components/Website.vue";
import Webpage from "../components/Webpage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: Default,
    children: [
      {
        path: "/",
        name: "home",
        component: Home,
      },
      {
        path: "/login",
        name: "login",
        component: Login,
      },
      {
        path: "/register",
        name: "register",
        component: Register,
      },
      {
        path: "/profile",
        name: "profile",
        component: Register,
      },
      {
        path: "/user",
        name: "user",
        component: User,
        meta: {
          title: "nav.users",
        },
      },
      {
        path: "/unit",
        name: "unit",
        component: Unit,
        meta: {
          title: "nav.units",
        },
      },
      {
        path: "/reseller",
        name: "reseller",
        component: Reseller,
        meta: {
          title: "nav.resellers",
        },
      },
      {
        path: "/release",
        name: "release",
        component: Release,
        meta: {
          title: "nav.releases",
        },
      },
      {
        path: "/webdata",
        component: Webdata,
        meta: {
          title: "nav.webdata",
        },
        children: [
          {
            path: "sslbump",
            name: "sslbump",
            component: Sslbump,
            meta: {
              title: "nav.sslbumps",
            },
          },
          {
            path: "website",
            name: "website",
            component: Website,
            meta: {
              title: "nav.websites",
            },
          },
          {
            path: "webpage",
            name: "webpage",
            component: Webpage,
            meta: {
              title: "nav.webpages",
            },
          },
        ],
      },
      {
        path: "/about",
        name: "about",
        meta: {
          title: "nav.about",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },
    ],
  },
  {
    path: "*",
    name: "error",
    component: Error,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
