import { UnitService } from "@/common/api.service";

const initialState = {};

const state = { ...initialState };

const getters = {};

const mutations = {};

const actions = {
  findUnits(_, params) {
    return UnitService.find(params).then(({ data }) => data);
  },
  fetchUnits(_, params) {
    return UnitService.fetchUnits(params).then(({ data }) => data);
  },
  fetchResellerUnits(_, params) {
    return UnitService.fetchResellerUnits(params).then(({ data }) => data);
  },
  fetchResellerUsers(_, params) {
    return UnitService.fetchResellerUsers(params).then(({ data }) => data);
  },
  getUnit(_, id) {
    return UnitService.get(id).then(({ data }) => data);
  },
  saveUnit(_, {
    id, user, users, unboxdate, unboxdates, unboxed, ip, boxver, description, ...body
  }) {
    const request = id
      ? UnitService.update(id, body)
      : UnitService.create(body);

    return request.then(({ data }) => data);
  },
  deleteUnit(_, id) {
    return UnitService.delete(id).then(({ data }) => data);
  },
  getVpnConfig() {
    return UnitService.getVpnConfig().then(({ data }) => data);
  },
  fetchVpnConfig() {
    return UnitService.fetchVpnConfig().then(({ data }) => data);
  },
  supportUnit(_, id) {
    return UnitService.supportUnit(id).then(({ data }) => data);
  },
  operateUnit(_, id) {
    return UnitService.operateUnit(id).then(({ data }) => data);
  },
  unitSupport(_, id) {
    return UnitService.unitSupport(id).then(({ data }) => data);
  },
  resetUnit(_, { id, ...body }) {
    return UnitService.resetUnit(id, body).then(({ data }) => data);
  },
  unitDescription(_, { id, ...body }) {
    return UnitService.unitDescription(id, body).then(({ data }) => data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
