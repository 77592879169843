import i18n from "../i18n";

export const roles = () => [
  { text: i18n.t("lbl.admin"), value: "admin" },
  { text: i18n.t("lbl.auditor"), value: "auditor" },
  { text: i18n.t("lbl.user"), value: "user" },
  { text: i18n.t("lbl.reseller"), value: "reseller" },
];

export const unbox = () => [
  { text: i18n.t("lbl.boxed"), value: "false" },
  { text: i18n.t("lbl.unboxed"), value: "true" },
];

export const editions = () => [
  { text: i18n.t("lbl.kids"), value: "kids" },
  { text: i18n.t("lbl.home"), value: "home" },
  { text: i18n.t("lbl.pro"), value: "pro" },
];
