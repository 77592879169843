export const email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const password = /^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z]{8,}$/;
export const semver = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;
export const guid = /^[0-9A-F]{8}-([0-9A-F]{4}-){3}[0-9A-F]{12}$/;
export const domain = /^(?:\w+:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?(?:m\.)?([^:/\n]+)/;
export const url = /^(?:\w+:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?(?:m\.)?(.*)/;

export default {
  email,
  password,
  semver,
  guid,
  domain,
  url,
};
