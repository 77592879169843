import { WebdataService } from "@/common/api.service";

const { Sslbump, Website, Webpage } = WebdataService;

const initialState = {};

const state = { ...initialState };

const getters = {};

const mutations = {};

const actions = {
  // Sslbump
  findSslbumps(_, params) {
    return Sslbump.find(params).then(({ data }) => data);
  },
  getSslbump(_, id) {
    return Sslbump.get(id).then(({ data }) => data);
  },
  saveSslbump(_, { id, date, ...body }) {
    const request = id
      ? Sslbump.update(id, body)
      : Sslbump.create(body);

    return request.then(({ data }) => data);
  },
  deleteSslbump(_, id) {
    return Sslbump.delete(id).then(({ data }) => data);
  },

  // Website
  findWebsites(_, params) {
    return Website.find(params).then(({ data }) => data);
  },
  getWebsite(_, id) {
    return Website.get(id).then(({ data }) => data);
  },
  saveWebsite(_, { id, date, ...body }) {
    const request = id
      ? Website.update(id, body)
      : Website.create(body);

    return request.then(({ data }) => data);
  },
  deleteWebsite(_, id) {
    return Website.delete(id).then(({ data }) => data);
  },

  // Webpage
  findWebpages(_, params) {
    return Webpage.find(params).then(({ data }) => data);
  },
  getWebpage(_, id) {
    return Webpage.get(id).then(({ data }) => data);
  },
  saveWebpage(_, { id, date, ...body }) {
    const request = id
      ? Webpage.update(id, body)
      : Webpage.create(body);

    return request.then(({ data }) => data);
  },
  deleteWebpage(_, id) {
    return Webpage.delete(id).then(({ data }) => data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
