<template>
  <div>
    <Release />
  </div>
</template>
<script>
import Release from "@/components/Release.vue";

export default {
  components: {
    Release,
  },
};
</script>
