import service from "./axios";

const ApiService = {
  find(resource, params) {
    return service.get(resource, params);
  },
  get(resource, slug) {
    return service.get(`${slug ? `${resource}/${slug}` : resource}`);
  },
  post(resource, body) {
    return service.post(resource, body);
  },
  postSlug(resource, slug, body) {
    return service.post(`${resource}/${slug}`, body);
  },
  postQuery(resource, query, body) {
    return service.post(resource, body, {
      params: query,
    });
  },
};

export default ApiService;

export const AuthService = {
  register(body) {
    return ApiService.post("auth/register", body);
  },
  login(body) {
    return ApiService.post("auth/login", body);
  },
  logout(body) {
    return ApiService.post("auth/logout", body);
  },
  forgotPassword(body) {
    return ApiService.post("auth/forgot-password", body);
  },
  resetPassword(query, body) {
    return ApiService.postQuery("auth/reset-password", query, body);
  },
  sendVerificationEmail(body) {
    return ApiService.post("auth/send-verification-email", body);
  },
  verifyEmail(query, body) {
    return ApiService.postQuery("auth/verify-email", query, body);
  },
};

export const UserService = {
  find(params) {
    return ApiService.find("user/findUsers", { params });
  },
  get(id) {
    return ApiService.get("user/getUser", id);
  },
  create(body) {
    return ApiService.post("user/createUser", body);
  },
  update(id, body) {
    return ApiService.postSlug("user/updateUser", id, body);
  },
  delete(id) {
    return ApiService.postSlug("user/deleteUser", id);
  },
  getProfile() {
    return ApiService.get("user/getProfile");
  },
  updateProfile(body) {
    return ApiService.post("user/updateProfile", body);
  },
};

export const UnitService = {
  find(params) {
    return ApiService.find("unit/findUnits", { params });
  },
  get(id) {
    return ApiService.get("unit/getUnit", id);
  },
  create(body) {
    return ApiService.post("unit/createUnit", body);
  },
  update(id, body) {
    return ApiService.postSlug("unit/updateUnit", id, body);
  },
  delete(id) {
    return ApiService.postSlug("unit/deleteUnit", id);
  },
  getVpnConfig() {
    return ApiService.get("unit/getVpnConfig");
  },
  supportUnit(id) {
    return ApiService.postSlug("unit/supportUnit", id);
  },
  unitSupport(id) {
    return ApiService.postSlug("unit/unitSupport", id);
  },
  resetUnit(id, body) {
    return ApiService.postSlug("unit/resetUnit", id, body);
  },
  // User methods
  fetchUnits(params) {
    return ApiService.find("unit/fetchUnits", { params });
  },
  fetchResellerUnits(params) {
    return ApiService.find("unit/fetchResellerUnits", { params });
  },
  fetchResellerUsers(params) {
    return ApiService.find("unit/fetchResellerUsers", { params });
  },
  fetchVpnConfig() {
    return ApiService.get("unit/fetchVpnConfig");
  },
  operateUnit(id) {
    return ApiService.postSlug("unit/operateUnit", id);
  },
  unitDescription(id, body) {
    return ApiService.postSlug("unit/unitDescription", id, body);
  },
};

export const ResellerService = {
  find(params) {
    return ApiService.find("reseller/findResellers", { params });
  },
  get(id) {
    return ApiService.get("reseller/getReseller", id);
  },
  create(body) {
    return ApiService.post("reseller/createReseller", body);
  },
  update(id, body) {
    return ApiService.postSlug("reseller/updateReseller", id, body);
  },
  delete(id) {
    return ApiService.postSlug("reseller/deleteReseller", id);
  },
};

export const ReleaseService = {
  find(params) {
    return ApiService.find("release/findReleases", { params });
  },
  get(id) {
    return ApiService.get("release/getRelease", id);
  },
  create(body) {
    return ApiService.post("release/createRelease", body);
  },
  update(id, body) {
    return ApiService.postSlug("release/updateRelease", id, body);
  },
  delete(id) {
    return ApiService.postSlug("release/deleteRelease", id);
  },
};

export const WebdataService = {
  Sslbump: {
    find(params) {
      return ApiService.find("webdata/findSslbumps", { params });
    },
    get(id) {
      return ApiService.get("webdata/getSslbump", id);
    },
    create(body) {
      return ApiService.post("webdata/createSslbump", body);
    },
    update(id, body) {
      return ApiService.postSlug("webdata/updateSslbump", id, body);
    },
    delete(id) {
      return ApiService.postSlug("webdata/deleteSslbump", id);
    },
  },
  Website: {
    find(params) {
      return ApiService.find("webdata/findSites", { params });
    },
    get(id) {
      return ApiService.get("webdata/getSite", id);
    },
    create(body) {
      return ApiService.post("webdata/createSite", body);
    },
    update(id, body) {
      return ApiService.postSlug("webdata/updateSite", id, body);
    },
    delete(id) {
      return ApiService.postSlug("webdata/deleteSite", id);
    },
  },
  Webpage: {
    find(params) {
      return ApiService.find("webdata/findPages", { params });
    },
    get(id) {
      return ApiService.get("webdata/getPage", id);
    },
    create(body) {
      return ApiService.post("webdata/createPage", body);
    },
    update(id, body) {
      return ApiService.postSlug("webdata/updatePage", id, body);
    },
    delete(id) {
      return ApiService.postSlug("webdata/deletePage", id);
    },
  },
};
