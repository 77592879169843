<template>
  <v-container>
    <v-row class="text-center">
      <v-col
        cols="12"
        class="mt-16"
      >
        <v-img
          :src="require('../assets/logo-ksg.png')"
          class="my-3"
          contain
          height="100"
        />
      </v-col>

      <v-col class="mt-4">
        <h1 class="display-2 font-weight-bold mb-3">
          {{ $t("lbl.logoTitle") }}
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    //
  }),
};
</script>
