import Vue from "vue";
import Toasted from "vue-toasted";
import VueSweetalert2 from "vue-sweetalert2";
import DatetimePicker from "vuetify-datetime-picker";
import luxon from "./common/luxon";
import helpers from "./common/helpers";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "sweetalert2/dist/sweetalert2.min.css";
import i18n from "./i18n";

Vue.use(Toasted, {
  position: "bottom-right",
  duration: 2000,
});

Vue.use(VueSweetalert2);
Vue.use(DatetimePicker);
Vue.use(luxon);

Vue.mixin(helpers);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
